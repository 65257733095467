var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$tc('shop.article.priceClass', 100),"items":_vm.articlePriceClasses,"headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultArticlePriceClassItem,"crudPermissionSubject":"ArticlePriceClass","crudURLPrefix":"/admin/article/priceclass"},on:{"crudDialogSave":function (updatedItems) { return (_vm.articlePriceClasses = updatedItems); }},scopedSlots:_vm._u([{key:"item.currencyPosition",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currencyPosition === 1 ? _vm.$t( "shop.article.priceClass.currency.position.beforePrice" ) : _vm.$t("shop.article.priceClass.currency.position.afterPrice"))+" ")]}},{key:"crud-form",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":_vm.$tc('language.name'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.priceClass.currency'),"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.currency),callback:function ($$v) {_vm.$set(item, "currency", $$v)},expression:"item.currency"}}),_c('v-checkbox',{attrs:{"label":((_vm.$t('shop.article.priceClass.currency.position')) + ": " + (item.currencyPosition === 1
                    ? _vm.$t(
                          'shop.article.priceClass.currency.position.beforePrice'
                      )
                    : _vm.$t(
                          'shop.article.priceClass.currency.position.afterPrice'
                      ))),"false-value":1,"true-value":2},model:{value:(item.currencyPosition),callback:function ($$v) {_vm.$set(item, "currencyPosition", $$v)},expression:"item.currencyPosition"}}),_c('v-text-field',{attrs:{"label":_vm.$t('shop.article.priceClass.tax'),"type":"number","rules":[function (v) { return !!v; }],"required":""},model:{value:(item.tax),callback:function ($$v) {_vm.$set(item, "tax", _vm._n($$v))},expression:"item.tax"}})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }