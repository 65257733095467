






import { Component, Vue } from "vue-property-decorator";
import AdminShopArticlePriceClassTable from "@/components/admin/shop/article/price/class/AdminShopArticlePriceClassTable.vue";

/**
 * AdminShopArticlePriceClassManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminShopArticlePriceClassTable,
    },
})
export default class AdminShopArticlePriceClassManagement extends Vue {}
