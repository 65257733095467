






























































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { ArticlePriceClass } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";

/**
 * AdminShopArticlePriceClassTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
    },
})
export default class AdminShopArticlePriceClassTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private articlePriceClasses: ArticlePriceClass[] = [];

    private defaultArticlePriceClassItem: ArticlePriceClass = {
        id: -1,
        name: "",
        currency: "",
        currencyPosition: 2,
        tax: 19,
    };

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("shop.article.priceClass").toString(),
                value: "name",
            },
            {
                text: this.$t("shop.article.priceClass.currency").toString(),
                value: "currency",
            },
            {
                text: this.$t(
                    "shop.article.priceClass.currency.position"
                ).toString(),
                value: "currencyPosition",
            },
            {
                text: this.$t("shop.article.priceClass.tax").toString(),
                value: "tax",
            },
        ];
    }

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.articlePriceClasses = await this.fetchArticlePriceClasses();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * Fetches all article price classes
     *
     * @returns Promise<ArticlePriceClass[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticlePriceClasses(): Promise<ArticlePriceClass[]> {
        const response = (
            await axios.get<APIResponse<ArticlePriceClass[]>>(
                "/articles/priceclasses"
            )
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
